import { truthy, falsy, roleType } from '/app.config';

/**
 * 检查文件大小是否小于指定的阈值
 * @param {Number} size 文件大小
 * @return {Boolean} 小于或不小于阈值
 */
const checkFizeSize = {
  // 是否小于 1M
  below1M(size) {
    return size / 1024 / 1024 < 1;
  }
};

/**
 * 基础状态格式化，只接受两种状态。
 * @param {status} status 状态值 0 或 1
 * @param {String} str1 为假值时需要格式化的字符串
 * @param {String} str2 为真值时需要格式化的字符串
 * @return {String} 格式化过后的字符串
 */
const baseFormatMapping = (status, ...strs) => {
  const mapping = {
    [truthy]: '',
    [falsy]: ''
  };

  if (strs.length) {
    mapping[truthy] = strs[0];
    mapping[falsy] = strs[1];
  } else {
    mapping[truthy] = '是';
    mapping[falsy] = '否';
  }

  return mapping[status];
};

/**
 * 格式化用户角色
 * @param {String} userType 用户类型
 * @return {String} 格式化过后的角色名称
 */
const roleFormat = userType => {
  const { admin, perform, companyAdmin, companyUser } = roleType;
  const mapping = {
    [admin]: '系统管理员',
    [perform]: '实施人员',
    [companyAdmin]: '企业管理员',
    [companyUser]: '企业员工'
  };
  return mapping[userType];
};

/**
 * 获取url参数
 * @param {String} variable 参数名
 * @return {String} querystring
 */
const getQueryVariable = variable => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};
export { checkFizeSize, baseFormatMapping, roleFormat, getQueryVariable };
