<template>
  <div class="play">
    <main class="main">
      <i class="iconfont icon-dcicon_jt_right_nor label" @click="showFactoryInfo"></i>
      <swiper ref="mySwiper" :options="options">
        <swiper-slide v-for="(map, index) in maps" :key="map.electronMapId">
          <div class="container" :class="`container${index}`"></div>
        </swiper-slide>
      </swiper>
    </main>
    <section class="popper">
      <Device ref="device"></Device>
    </section>
  </div>
</template>

<script>
import Device from './components/drawer.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import generateMap from './utils/generateMap.js';
import Swiper2, { Autoplay } from 'swiper';
Swiper2.use(Autoplay);
import deviceRunState from './utils/deviceRuningState';
import 'swiper/swiper-bundle.min.css';
import { $main_theme_color } from './style/index';
import { getQueryVariable } from '@/common/js/utils.js';
import { base64ToUtf8 } from '@/common/js/base64';
import { polling_time } from './config';
export default {
  data() {
    return {
      maps: [],
      delay: 0,
      factory: {
        id: '',
        mapId: ''
      },
      interValId: null
    };
  },
  created() {
    this.getQueryParams();
  },
  methods: {
    getQueryParams() {
      this.delay = getQueryVariable('delay');
      this.factory.id = getQueryVariable('factoryId');
      this.factory.mapId = getQueryVariable('mapId');

      this.loadData();
    },
    async loadData() {
      const factory = this.factory.id;
      const map = this.factory.mapId;

      if (factory) {
        // 循环播放时，获取工厂下的地图列表
        const { electronMapList } = await this.$apis.electron.electronList({ factoryId: this.factory.id });
        this.maps = electronMapList.filter(map => map.mapContent);
      } else if (map) {
        // 固定播放时，获取某个地图
        const map = await this.$apis.electron.electronDetails({ electronMapId: this.factory.mapId });
        this.maps.push(map);
      }

      this.showFactoryInfo();
    },
    async renderMap() {
      document.querySelectorAll('.container').forEach((container, index) => {
        const mapContent = JSON.parse(base64ToUtf8(this.maps[index].mapContent));
        const graph = generateMap(container, {
          background: {
            color: $main_theme_color
          }
        });

        graph.fromJSON(mapContent);

        deviceRunState(graph.getNodes());
      });
    },
    // 显示工厂信息
    showFactoryInfo() {
      if (this.interValId) {
        clearInterval(this.interValId);
        this.interValId = null;
      }

      this.$nextTick(() => {
        this.polling();
      });

      this.$refs.device.changeVisible();
    },
    polling() {
      this.renderMap();

      this.interValId = setInterval(() => {
        this.renderMap();
      }, polling_time);
    }
  },
  computed: {
    options() {
      const options = {};

      if (this.delay) {
        options.speed = 2000;
        options.autoplay = {
          delay: Number(this.delay) * 1000,
          disableOnInteraction: false
        };
      }

      return options;
    }
  },
  components: {
    Device,
    Swiper,
    SwiperSlide
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import './style/index.scss';
.play {
  width: 100vw;
  height: 100vh;
  background: #121212;
  overflow: hidden;
  .container {
    width: 100vw;
    height: 100vh;
  }
  .label {
    display: inline-block;
    color: rgb(136, 136, 136);
    border-radius: 3px;
    background: $secondary-color;
    position: fixed;
    top: 50%;
    left: -2px;
    cursor: pointer;
    z-index: 2;
    &:hover {
      color: $--color-primary;
    }
  }
}
</style>
